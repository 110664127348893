

















































































































































































































import Vue from 'vue';
import { formatDate } from '@/utils/tools';
import Scaffold from '@/components/Presenter/Scaffold.vue';
import SessionSection from '@/components/Presenter/SessionSection.vue';

import { mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  components: { Scaffold, SessionSection },
  data() {
    return {
      selectedDay: [],
      showAccountMenu: false,

      buttons: [
        {
          label: this.$t('views.presenter.homePlanSession'),
          onClick: () => {
            this.$router.push({ name: 'New Tour' });
          },
        },
      ],
    };
  },
  async mounted() {
    const userId = await this.$store.getters['auth/getUserId'];
    await this.$store.dispatch('presenter/bindSessionsRef', userId);
    await this.$store.dispatch('presenter/bindUnlimitedSessionsRef', userId);
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      sessions: 'presenter/getSessions',
      unlimitedSessions: 'presenter/getUnlimitedSessions',
    }),

    computedAttr(): any {
      return this.sessions.map((session: any) => {
        const sessionRunning =
          session.startAt.toDate() < Date.now() &&
          session.endAt.toDate() > Date.now();

        let dateColor = 'green';
        let higherOrder = 1;

        if (!sessionRunning) {
          dateColor = 'blue';
        } else {
          dateColor = 'green';
          higherOrder = 0;
        }
        return {
          highlight: {
            start: { fillMode: 'solid', color: dateColor },
            base: { fillMode: 'light', color: dateColor },
            end: { fillMode: 'solid', color: dateColor },
          },
          dates: {
            start: session.startAt.toDate(),
            end: session.endAt.toDate(),
          },
          popover: {
            label: session.name,
          },
          customData: session,
          order: higherOrder,
        };
      });
    },
  },
  methods: {
    ...mapMutations({
      reset: 'presenter/RESET',
    }),

    onDayClick(day: any) {
      this.selectedDay = day;
    },

    clearSelectDay() {
      this.selectedDay = [];
    },

    formatDate(input: any): string {
      return formatDate(input);
    },
    goToNewSession(): void {
      this.$router.push({
        name: 'New Presenter Session',
      });
    },
  },
  beforeDestroy() {
    this.reset();
  },
});
